import { render, staticRenderFns } from "./index.vue?vue&type=template&id=252f58a2&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CImage: require('@chakra-ui/vue').CImage, CMenuButton: require('@chakra-ui/vue').CMenuButton, CFlex: require('@chakra-ui/vue').CFlex, CMenuItem: require('@chakra-ui/vue').CMenuItem, CMenuList: require('@chakra-ui/vue').CMenuList, CMenu: require('@chakra-ui/vue').CMenu, CButton: require('@chakra-ui/vue').CButton, CMenuItemOption: require('@chakra-ui/vue').CMenuItemOption, CMenuOptionGroup: require('@chakra-ui/vue').CMenuOptionGroup, CStack: require('@chakra-ui/vue').CStack, CBox: require('@chakra-ui/vue').CBox, CInputLeftAddon: require('@chakra-ui/vue').CInputLeftAddon, CInput: require('@chakra-ui/vue').CInput, CInputGroup: require('@chakra-ui/vue').CInputGroup, CSelect: require('@chakra-ui/vue').CSelect, CIcon: require('@chakra-ui/vue').CIcon})
