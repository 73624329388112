var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex-dir": "column",
      "w": "100%"
    }
  }, [_c('c-text', [_vm._v("Manajemen Klien")]), _c('hr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "my": "10px"
    }
  }), _c('c-flex', {
    attrs: {
      "align-items": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "flex": "1",
      "font-size": "2xl"
    }
  }, [_vm._v(" Manajemen Klien ")]), _vm.isRoleSuperAdmin ? _c('c-menu', {
    attrs: {
      "close-on-select": true
    }
  }, [_c('c-menu-button', {
    attrs: {
      "h": "auto",
      "py": "12px",
      "px": "28px",
      "color": "#927102",
      "border-radius": "24px",
      "border-color": "transparent",
      "bg": "secondary.800",
      "variant-color": "inherit",
      "font-size": "16px",
      "line-height": "24px",
      "variant": "solid",
      "display": ['none', 'flex']
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-plus.svg'),
      "alt": "icon",
      "display": "inline-block",
      "mr": "10px"
    }
  }), _vm._v(" Tambah Client ")], 1), _c('c-menu-list', {
    attrs: {
      "z-index": "10"
    }
  }, _vm._l(_vm.clientTypes, function (item) {
    return _c('c-menu-item', {
      key: item.value,
      attrs: {
        "value": item.value,
        "border-bottom": "1px solid #F2F2F2",
        "font-size": "18px",
        "py": "10px"
      },
      on: {
        "click": function click($event) {
          return _vm.$router.push(item.route);
        }
      }
    }, [_c('c-flex', [_c('c-image', {
      attrs: {
        "src": item.icon,
        "alt": "icon",
        "display": "inline-block",
        "mr": "10px"
      }
    }), _c('c-text', [_vm._v(_vm._s(item.label))])], 1)], 1);
  }), 1)], 1) : _vm._e()], 1), _c('c-box', {
    attrs: {
      "mt": "30px",
      "w": "100%",
      "border": "1px solid #f2f2f2",
      "box-shadow": "4px 4px 50px 5px #0000000D",
      "rounded": "lg",
      "overflow": "hidden"
    }
  }, [_c('c-flex', {
    attrs: {
      "align-items": "center",
      "px": "20px",
      "h": "75px"
    }
  }, [_c('c-text', {
    attrs: {
      "flex": "1"
    }
  }, [_vm._v(" Daftar Klien ")]), _c('c-stack', {
    attrs: {
      "is-inline": "",
      "spacing": "10px"
    }
  }, [_vm.isRoleSuperAdmin ? _c('c-button', {
    attrs: {
      "h": "40px",
      "rounded": "full",
      "color": "gray.700",
      "variant": "outline",
      "variant-color": "gray",
      "font-size": "14px",
      "font-weight": "400",
      "type": "button",
      "as": "a",
      "href": _vm.exportUrl,
      "download": "clients.xls"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-file-export.svg'),
      "alt": "icon",
      "display": "inline-block",
      "mr": "10px"
    }
  }), _vm._v(" Export ")], 1) : _vm._e(), _c('c-menu', {
    attrs: {
      "close-on-select": true
    }
  }, [_c('c-menu-button', {
    attrs: {
      "border-radius": "40px",
      "background-color": "superLightGray.900",
      "pl": "20px",
      "pr": "30px",
      "py": "10px",
      "h": "40px",
      "color": "gray.900",
      "font-size": "14px",
      "line-height": "21px",
      "font-family": "Roboto",
      "font-weight": "normal",
      "display": ['none', 'flex']
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-filter.svg'),
      "alt": "icon",
      "display": "inline-block",
      "margin-right": "5px"
    }
  }), _vm._v(" Filter ")], 1), _c('c-menu-list', {
    attrs: {
      "z-index": "10",
      "min-width": "240px"
    }
  }, [_c('c-menu-option-group', {
    attrs: {
      "default-value": "Semua",
      "type": "radio"
    },
    on: {
      "change": function change($event) {
        _vm.status = $event;
      }
    }
  }, _vm._l(_vm.statuses, function (item) {
    return _c('c-menu-item-option', {
      key: item.value,
      attrs: {
        "value": item.value,
        "border-bottom": "1px solid #F2F2F2",
        "font-size": "18px",
        "line-height": "27px",
        "py": "16px",
        "px": "20px"
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 1)], 1)], 1)], 1), _c('c-box', {
    attrs: {
      "border-left": "1px solid #f2f2f2",
      "h": "70%",
      "w": "2px",
      "mx": "20px"
    }
  }), _c('c-input-group', {
    attrs: {
      "bg": "#F2F2F2",
      "rounded": "md"
    }
  }, [_c('c-input-left-addon', {
    attrs: {
      "bg": "none",
      "border": "none"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-search.svg')
    }
  })], 1), _c('c-input', {
    attrs: {
      "bg": "none",
      "border": "none",
      "size": "sm",
      "px": "0",
      "placeholder": "Pencarian",
      "_placeholder": {
        color: '#555',
        opacity: 1
      }
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)], 1), _c('table', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "w": "100%"
    }
  }, [_c('thead', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "bg": "#008C8137",
      "color": "primary.400",
      "text-align": "left",
      "h": "40px",
      "text-transform": "uppercase",
      "font-weight": "700",
      "font-size": "12px"
    }
  }, [_c('tr', [_c('th', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "pl": "20px"
    }
  }, [_vm._v(" No ")]), _c('th', [_vm._v("Foto Profil")]), _c('th', [_vm._v("Nama")]), _c('th', [_vm._v("Nomor HP")]), _c('th', [_vm._v("Email")]), _c('th', [_vm._v("Status")]), _c('th', [_vm._v("Action")])])]), _vm.clients.length === 0 ? _c('tbody', [_c('tr', [_c('td', {
    attrs: {
      "colspan": "7"
    }
  }, [_c('c-flex', {
    attrs: {
      "py": "80px",
      "px": "20px",
      "justify-content": "center"
    }
  }, [_c('c-box', [_c('c-image', {
    attrs: {
      "src": require('@/assets/empty-nutritionists.svg'),
      "alt": "empty"
    }
  }), _c('c-text', {
    attrs: {
      "color": "gray.900",
      "font-family": "Roboto",
      "font-size": "24px",
      "margin-top": "20px"
    }
  }, [_vm._v(" Klien tidak ditemukan ")])], 1)], 1)], 1)])]) : _c('tbody', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "text-align": "left",
      "font-size": "14px",
      "font-weight": "400"
    }
  }, _vm._l(_vm.clients, function (item, index) {
    return _c('tr', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra"
      }],
      key: index,
      attrs: {
        "h": "90px",
        "border": index > 0 ? '1px solid #f2f2f2' : null
      }
    }, [_c('td', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra"
      }],
      attrs: {
        "pl": "20px"
      }
    }, [_vm._v(" " + _vm._s(+_vm.rows * (_vm.page - 1) + (index + 1)) + " ")]), _c('td', [_c('c-image', {
      attrs: {
        "h": "58px",
        "w": "58px",
        "object-fit": "cover",
        "rounded": "full",
        "src": _vm.getAvatarUrl(item.photoUrl)
      }
    })], 1), _c('td', [_vm._v(_vm._s(item.firstName) + " " + _vm._s(item.lastName))]), _c('td', [_vm._v(_vm._s(item.phone))]), _c('td', [_vm._v(_vm._s(item.email))]), item.status === 'active' ? _c('td', [_c('c-box', {
      attrs: {
        "rounded": "full",
        "d": "flex",
        "align-items": "center",
        "justify-content": "center",
        "bg": "#0075E11A",
        "border": "1px solid #0075E1",
        "w": "fit-content",
        "py": "5px",
        "px": "10px"
      }
    }, [_c('c-text', {
      attrs: {
        "color": "#0075E1",
        "font-size": "10px",
        "font-style": "normal",
        "font-weight": "400",
        "line-height": "15px"
      }
    }, [_vm._v(" Aktif ")])], 1)], 1) : item.status === 'pending' ? _c('td', [_c('c-box', {
      attrs: {
        "rounded": "full",
        "d": "flex",
        "align-items": "center",
        "justify-content": "center",
        "bg": "rgba(244, 204, 70, 0.2)",
        "border": "1px solid #F4CC46",
        "w": "fit-content",
        "py": "5px",
        "px": "10px"
      }
    }, [_c('c-text', {
      attrs: {
        "color": "#F4CC46",
        "font-size": "10px",
        "font-style": "normal",
        "font-weight": "400",
        "line-height": "15px"
      }
    }, [_vm._v(" Pending ")])], 1)], 1) : item.status === 'done' || item.status === 'non_active' ? _c('td', [_c('c-box', {
      attrs: {
        "rounded": "full",
        "d": "flex",
        "align-items": "center",
        "justify-content": "center",
        "bg": "#EA43351A",
        "border": "1px solid #EA4335",
        "w": "fit-content",
        "py": "5px",
        "px": "10px"
      }
    }, [_c('c-text', {
      attrs: {
        "color": "#EA4335",
        "font-size": "10px",
        "font-style": "normal",
        "font-weight": "400",
        "line-height": "15px"
      }
    }, [_vm._v(" Tidak Aktif ")])], 1)], 1) : _c('td', [_vm._v("   ")]), _c('td', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra"
      }]
    }, [_c('c-button', {
      attrs: {
        "type": "button",
        "variant": "outline",
        "variant-color": "primary",
        "rounded": "full",
        "w": "74px",
        "h": "30px",
        "as": "router-link",
        "to": {
          name: 'superadmin.clients-detail',
          params: {
            clientId: item.id
          }
        }
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": "12px",
        "font-weight": "700",
        "line-height": "18px"
      }
    }, [_vm._v(" Lihat ")])], 1)], 1)]);
  }), 0)]), _c('c-flex', {
    attrs: {
      "h": "85px",
      "align-items": "center",
      "px": "40px"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex": "1",
      "align-items": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "#888",
      "font-weight": "400",
      "font-size": "14px"
    }
  }, [_vm._v(" Show : ")]), _c('c-input-group', [_c('c-select', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "appearance": "none",
      "d": "inline-block",
      "rounded": "md",
      "ml": "10px"
    },
    model: {
      value: _vm.rows,
      callback: function callback($$v) {
        _vm.rows = $$v;
      },
      expression: "rows"
    }
  }, [_c('option', {
    attrs: {
      "value": "10"
    }
  }, [_vm._v(" 10 baris ")]), _c('option', {
    attrs: {
      "value": "30"
    }
  }, [_vm._v(" 30 baris ")]), _c('option', {
    attrs: {
      "value": "50"
    }
  }, [_vm._v(" 50 baris ")])])], 1)], 1), _c('c-flex', {
    attrs: {
      "justify-content": "flex-end"
    }
  }, [_c('c-flex', {
    attrs: {
      "justify-content": "center",
      "align": "center"
    }
  }, [_c('c-button', {
    attrs: {
      "type": "button",
      "p": "0",
      "mr": "16px",
      "w": "32px",
      "h": "32px",
      "min-w": "32px",
      "variant": "outline",
      "border-radius": "30px",
      "background-color": "transparent",
      "is-disabled": _vm.page === 1,
      "as": "router-link",
      "to": {
        query: Object.assign({}, _vm.$route.query, {
          page: _vm.page - 1
        })
      }
    }
  }, [_c('c-icon', {
    attrs: {
      "name": "chevron-left",
      "size": "24px"
    }
  })], 1), _vm._l(_vm.pages, function (p, index) {
    return _c('c-button', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          ':hover': {
            bg: 'rgba(0, 140, 129, 0.3)',
            color: 'primary.400'
          }
        },
        expression: "{\n                ':hover': {\n                  bg: 'rgba(0, 140, 129, 0.3)',\n                  color: 'primary.400',\n                },\n              }"
      }],
      key: index,
      attrs: {
        "p": "0",
        "m": "0",
        "w": "32px",
        "h": "32px",
        "min-w": "32px",
        "mx": "4px",
        "border-radius": "30px",
        "background-color": "rgba(0, 140, 129, 0.3)",
        "color": "primary.400",
        "bg": p === _vm.page ? ['rgba(0, 140, 129, 0.3)'] : ['transparent'],
        "is-disabled": isNaN(+p),
        "as": "router-link",
        "to": {
          name: _vm.$route.name,
          query: Object.assign({}, _vm.$route.query, {
            page: p
          })
        }
      }
    }, [_vm._v(" " + _vm._s(p) + " ")]);
  }), _c('c-button', {
    attrs: {
      "p": "0",
      "ml": "16px",
      "w": "32px",
      "h": "32px",
      "min-w": "32px",
      "variant": "outline",
      "border-radius": "30px",
      "background-color": "transparent",
      "is-disabled": _vm.pages.length === _vm.page,
      "as": "router-link",
      "to": {
        query: Object.assign({}, _vm.$route.query, {
          page: _vm.page + 1
        })
      }
    }
  }, [_c('c-icon', {
    attrs: {
      "name": "chevron-right",
      "size": "24px"
    }
  })], 1)], 2)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }