<template>
  <c-flex
    flex-dir="column"
    w="100%"
  >
    <c-text>Manajemen Klien</c-text>
    <hr
      v-chakra
      my="10px"
    >

    <c-flex align-items="center">
      <c-text
        flex="1"
        font-size="2xl"
      >
        Manajemen Klien
      </c-text>
      <c-menu
        v-if="isRoleSuperAdmin"
        :close-on-select="true"
      >
        <c-menu-button
          h="auto"
          py="12px"
          px="28px"
          color="#927102"
          border-radius="24px"
          border-color="transparent"
          bg="secondary.800"
          variant-color="inherit"
          font-size="16px"
          line-height="24px"
          variant="solid"
          :display="['none', 'flex']"
        >
          <c-image
            :src="require('@/assets/icon-plus.svg')"
            alt="icon"
            display="inline-block"
            mr="10px"
          />
          Tambah Client
        </c-menu-button>
        <c-menu-list z-index="10">
          <c-menu-item
            v-for="item in clientTypes"
            :key="item.value"
            :value="item.value"
            border-bottom="1px solid #F2F2F2"
            font-size="18px"
            py="10px"
            @click="$router.push(item.route)"
          >
            <c-flex>
              <c-image
                :src="item.icon"
                alt="icon"
                display="inline-block"
                mr="10px"
              />
              <c-text>{{ item.label }}</c-text>
            </c-flex>
          </c-menu-item>
        </c-menu-list>
      </c-menu>
    </c-flex>

    <c-box
      mt="30px"
      w="100%"
      border="1px solid #f2f2f2"
      box-shadow="4px 4px 50px 5px #0000000D"
      rounded="lg"
      overflow="hidden"
    >
      <c-flex
        align-items="center"
        px="20px"
        h="75px"
      >
        <c-text flex="1">
          Daftar Klien
        </c-text>
        <c-stack
          is-inline
          spacing="10px"
        >
          <c-button
            v-if="isRoleSuperAdmin"
            h="40px"
            rounded="full"
            color="gray.700"
            variant="outline"
            variant-color="gray"
            font-size="14px"
            font-weight="400"
            type="button"
            as="a"
            :href="exportUrl"
            download="clients.xls"
          >
            <c-image
              :src="require('@/assets/icon-file-export.svg')"
              alt="icon"
              display="inline-block"
              mr="10px"
            />
            Export
          </c-button>
          <c-menu :close-on-select="true">
            <c-menu-button
              border-radius="40px"
              background-color="superLightGray.900"
              pl="20px"
              pr="30px"
              py="10px"
              h="40px"
              color="gray.900"
              font-size="14px"
              line-height="21px"
              font-family="Roboto"
              font-weight="normal"
              :display="['none', 'flex']"
            >
              <c-image
                :src="require('@/assets/ic-filter.svg')"
                alt="icon"
                display="inline-block"
                margin-right="5px"
              />
              Filter
            </c-menu-button>
            <c-menu-list
              z-index="10"
              min-width="240px"
            >
              <c-menu-option-group
                default-value="Semua"
                type="radio"
                @change="status = $event"
              >
                <c-menu-item-option
                  v-for="item in statuses"
                  :key="item.value"
                  :value="item.value"
                  border-bottom="1px solid #F2F2F2"
                  font-size="18px"
                  line-height="27px"
                  py="16px"
                  px="20px"
                >
                  {{ item.label }}
                </c-menu-item-option>
              </c-menu-option-group>
            </c-menu-list>
          </c-menu>
        </c-stack>

        <c-box
          border-left="1px solid #f2f2f2"
          h="70%"
          w="2px"
          mx="20px"
        />
        <c-input-group
          bg="#F2F2F2"
          rounded="md"
        >
          <c-input-left-addon
            bg="none"
            border="none"
          >
            <c-image :src="require('@/assets/ic-search.svg')" />
          </c-input-left-addon>
          <c-input
            v-model="search"
            bg="none"
            border="none"
            size="sm"
            px="0"
            placeholder="Pencarian"
            :_placeholder="{
              color: '#555',
              opacity: 1,
            }"
          />
        </c-input-group>
      </c-flex>
      <table
        v-chakra
        w="100%"
      >
        <thead
          v-chakra
          bg="#008C8137"
          color="primary.400"
          text-align="left"
          h="40px"
          text-transform="uppercase"
          font-weight="700"
          font-size="12px"
        >
          <tr>
            <th
              v-chakra
              pl="20px"
            >
              No
            </th>
            <th>Foto Profil</th>
            <th>Nama</th>
            <th>Nomor HP</th>
            <th>Email</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody v-if="clients.length === 0">
          <tr>
            <td colspan="7">
              <c-flex
                py="80px"
                px="20px"
                justify-content="center"
              >
                <c-box>
                  <c-image
                    :src="require('@/assets/empty-nutritionists.svg')"
                    alt="empty"
                  />
                  <c-text
                    color="gray.900"
                    font-family="Roboto"
                    font-size="24px"
                    margin-top="20px"
                  >
                    Klien tidak ditemukan
                  </c-text>
                </c-box>
              </c-flex>
            </td>
          </tr>
        </tbody>

        <tbody
          v-else
          v-chakra
          text-align="left"
          font-size="14px"
          font-weight="400"
        >
          <tr
            v-for="(item, index) in clients"
            :key="index"
            v-chakra
            h="90px"
            :border="index > 0 ? '1px solid #f2f2f2' : null"
          >
            <td
              v-chakra
              pl="20px"
            >
              {{ +rows * (page - 1) + (index + 1) }}
            </td>
            <td>
              <c-image
                h="58px"
                w="58px"
                object-fit="cover"
                rounded="full"
                :src="getAvatarUrl(item.photoUrl)"
              />
            </td>
            <td>{{ item.firstName }} {{ item.lastName }}</td>
            <td>{{ item.phone }}</td>
            <td>{{ item.email }}</td>
            <td v-if="item.status === 'active'">
              <c-box
                rounded="full"
                d="flex"
                align-items="center"
                justify-content="center"
                bg="#0075E11A"
                border="1px solid #0075E1"
                w="fit-content"
                py="5px"
                px="10px"
              >
                <c-text
                  color="#0075E1"
                  font-size="10px"
                  font-style="normal"
                  font-weight="400"
                  line-height="15px"
                >
                  Aktif
                </c-text>
              </c-box>
            </td>
            <td v-else-if="item.status === 'pending'">
              <c-box
                rounded="full"
                d="flex"
                align-items="center"
                justify-content="center"
                bg="rgba(244, 204, 70, 0.2)"
                border="1px solid #F4CC46"
                w="fit-content"
                py="5px"
                px="10px"
              >
                <c-text
                  color="#F4CC46"
                  font-size="10px"
                  font-style="normal"
                  font-weight="400"
                  line-height="15px"
                >
                  Pending
                </c-text>
              </c-box>
            </td>
            <td v-else-if="item.status === 'done' || item.status === 'non_active'">
              <c-box
                rounded="full"
                d="flex"
                align-items="center"
                justify-content="center"
                bg="#EA43351A"
                border="1px solid #EA4335"
                w="fit-content"
                py="5px"
                px="10px"
              >
                <c-text
                  color="#EA4335"
                  font-size="10px"
                  font-style="normal"
                  font-weight="400"
                  line-height="15px"
                >
                  Tidak Aktif
                </c-text>
              </c-box>
            </td>
            <td v-else>
&nbsp;
            </td>
            <td v-chakra>
              <c-button
                type="button"
                variant="outline"
                variant-color="primary"
                rounded="full"
                w="74px"
                h="30px"
                as="router-link"
                :to="{
                  name: 'superadmin.clients-detail',
                  params: { clientId: item.id },
                }"
              >
                <c-text
                  font-size="12px"
                  font-weight="700"
                  line-height="18px"
                >
                  Lihat
                </c-text>
              </c-button>
            </td>
          </tr>
        </tbody>
      </table>
      <c-flex
        h="85px"
        align-items="center"
        px="40px"
      >
        <c-flex
          flex="1"
          align-items="center"
        >
          <c-text
            color="#888"
            font-weight="400"
            font-size="14px"
          >
            Show :
          </c-text>
          <c-input-group>
            <c-select
              v-model="rows"
              v-chakra
              appearance="none"
              d="inline-block"
              rounded="md"
              ml="10px"
            >
              <option value="10">
                10 baris
              </option>
              <option value="30">
                30 baris
              </option>
              <option value="50">
                50 baris
              </option>
            </c-select>
          </c-input-group>
        </c-flex>

        <c-flex justify-content="flex-end">
          <c-flex
            justify-content="center"
            align="center"
          >
            <c-button
              type="button"
              p="0"
              mr="16px"
              w="32px"
              h="32px"
              min-w="32px"
              variant="outline"
              border-radius="30px"
              background-color="transparent"
              :is-disabled="page === 1"
              as="router-link"
              :to="{ query: { ...$route.query, page: page - 1 } }"
            >
              <c-icon
                name="chevron-left"
                size="24px"
              />
            </c-button>

            <c-button
              v-for="(p, index) in pages"
              :key="index"
              v-chakra="{
                ':hover': {
                  bg: 'rgba(0, 140, 129, 0.3)',
                  color: 'primary.400',
                },
              }"
              p="0"
              m="0"
              w="32px"
              h="32px"
              min-w="32px"
              mx="4px"
              border-radius="30px"
              background-color="rgba(0, 140, 129, 0.3)"
              color="primary.400"
              :bg="p === page ? ['rgba(0, 140, 129, 0.3)'] : ['transparent']"
              :is-disabled="isNaN(+p)"
              as="router-link"
              :to="{ name: $route.name, query: { ...$route.query, page: p } }"
            >
              {{ p }}
            </c-button>

            <c-button
              p="0"
              ml="16px"
              w="32px"
              h="32px"
              min-w="32px"
              variant="outline"
              border-radius="30px"
              background-color="transparent"
              :is-disabled="pages.length === page"
              as="router-link"
              :to="{ query: { ...$route.query, page: page + 1 } }"
            >
              <c-icon
                name="chevron-right"
                size="24px"
              />
            </c-button>
          </c-flex>
        </c-flex>
      </c-flex>
    </c-box>
  </c-flex>
</template>

<script>
import { mapState } from 'vuex'
import { calculatePaginations } from '@/utils/calculate-paginations'

export default {
  name: 'SUClientList',
  data() {
    return {
      clientTypes: [
        {
          label: 'Tambah Client',
          icon: require('@/assets/icon-user-active.svg'),
          route: {
            name: 'superadmin.clients-new',
          },
        },
        {
          label: 'Tambah Paying Client',
          icon: require('@/assets/ic-user-pay.svg'),
          route: {
            name: 'superadmin.clients-new',
            query: { paying: true },
          },
        },
      ],
      statuses: [
        { label: 'Semua', value: undefined },
        { label: 'Aktif', value: 'active' },
        { label: 'Tidak Aktif', value: 'non_active' },
        { label: 'Pending', value: 'pending' },
      ],
    }
  },
  computed: {
    ...mapState({
      clients: (s) => s.suManagementClient.clients,
      totalData: (s) => s.suManagementClient.meta.totalData,
    }),
    page: {
      get() {
        return +(this.$route.query.page ?? 1)
      },
      set(v) {
        if (+this.$route.query.page === v) return
        this.$router.replace({
          query: { ...this.$route.query, page: v },
        })
      },
    },
    search: {
      get() {
        return this.$route.query.q
      },
      set(v) {
        if (this.$route.query.q === v) return
        this.$router.replace({ query: { ...this.$route.query, q: v } })
      },
    },
    rows: {
      get() {
        return this.$route.query.rows ?? '10'
      },
      set(v) {
        if (this.$route.query.rows === v) return
        this.$router.replace({
          query: {
            ...this.$router.query,
            rows: v,
          },
        })
      },
    },
    status: {
      get() {
        return this.$route.query.status
      },
      set(v) {
        if (this.$route.query.status === v) return
        this.$router.replace({ query: { ...this.$route.query, status: v } })
      },
    },
    pages() {
      return calculatePaginations({
        perPage: +this.rows,
        totalData: this.totalData,
        currentPage: this.page,
      })
    },
    filters() {
      return {
        page: this.page,
        perPage: this.rows,
        search: this.search,
        status: this.status,
      }
    },
    exportUrl() {
      let baseUrl = this.$store.getters.axios.defaults.baseURL
      let token = this.$store.state.auth.token
      let params = new URLSearchParams()
      params.set('token', token)
      if (this.filters.status != null) params.set('status', this.filters.status)

      return `${baseUrl}/v1/super-admin/clients/export?${params.toString()}`
    },
  },
  watch: {
    filters: {
      immediate: true,
      async handler(filter) {
        await this.$store.dispatch('suManagementClient/listClients', filter)
      },
    },
  },
  methods: {
    getAvatarUrl(avatarUrl) {
      return avatarUrl ?? 'https://ik.imagekit.io/dietela/pwa_webp/profile/profile_neutral.webp'
    },
  },
}
</script>
